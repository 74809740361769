import React from 'react';

import './contactUs.scss';

export default (): JSX.Element => {
    return (
        <div className="contactForm__container">
            <h1 className="contactForm__title">What&rsquo;s on your mind?</h1>
            <form name="contact" method="post" netlify-honeypot="bot-field" data-netlify="true" >
                <label>Your Name</label>
                <input type="hidden" name="form-name" value="contact" />
                <input type="text" name="name" placeholder="Enter your name" className="form-control" />
                <label>Email</label>
                <input type="email" name="email" placeholder="Enter your email address" className="form-control"/>
                <label>Message</label>
                <textarea name="message" className="form-control" placeholder="Your message here..."></textarea>
                <p className="hidden">
                    <label>Don’t fill this out if you’re human: <input name="bot-field" /></label>
                </p>
                <button type="submit" className="btn btn-primary">Submit</button>
            </form>
        </div>
    );
};
